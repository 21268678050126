<template>
  <v-app>
  <div class="card card-custom">
    <div class="card-body p-0">
      <div
        class="wizard wizard-2"
        id="kt_wizard_v2"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
          <div class="row text-left">
            <div class="col-md-6">
              <h3>
               Online inquiry
              </h3>
              <router-link :to="{name:'dashboard'}">
                Dashboard
              </router-link> \
              Online inquiry
            </div>
          </div>
          <div class="row bg-filter">
            <div class="col-4">
              <v-text-field label="Name, Email, Subject, Phone"
                type="text"
                dense
                outlined
                v-model="search.title"
                placeholder="Name, Email, Subject, Phone"
              />
            </div>
            <div class="col-md-2">
              <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                          v-model="search.publish_date_from"
                          label="From Date"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search.publish_date_from"
                               @input="menu2 = false"></v-date-picker>
              </v-menu>
            </div>
            <div class="col-md-2">
              <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                          v-model="search.publish_date_to"
                          label="To Date"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search.publish_date_to"
                               @input="menu = false"></v-date-picker>
              </v-menu>
            </div>
            <div class="col-md-2" >
              <v-select
                      outlined
                      dense
                      v-model="search.filter_by"
                      :items="['read','replied','download']"
                      label="Filter By"
              >

              </v-select>
            </div>
            <div class="col-md-2">
              <button class="btn btn-primary" @click="getMessages">Search</button>
<!--              <button class="btn btn-secondary ml-2" @click="_reset">Reset</button>-->
            </div>
          </div>
          <v-row v-if="isLoading">
            <v-col cols="12">
              <v-skeleton-loader
                      class="mx-auto"
                      type="table"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <div class="row" v-else>

            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-stripe">
                  <thead>
                    <th>Date</th>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>More Info</th>
<!--                    <th>Subject</th>-->
                    <th>Status</th>
                    <th>Replied</th>
                    <th class="text-center">Action</th>
                  </thead>
                  <tbody>
                    <tr v-for="(message, index) of contacts" :key="index">
                      <td><a href="#" @click="openDialog(message.id)">{{message.date}}</a> </td>
                      <td>{{message.first_name}} {{message.last_name}}</td>
                      <td>{{message.email}}</td>
                      <td>{{message.phone}}</td>
                      <td>
                        <p><b>Country: </b> {{message.country }}</p>
                        <p><b>Education Level: </b> {{message.education_level }}</p>
                        <p><b>Academic Session: </b> {{message.academic_session}}</p>
                        <p><b>Academic Program Category: </b> {{message.academic_program_category}}</p>
                      </td>
<!--                      <td>{{message.subject}}</td>-->
                      <td>
                        <span class="badge badge-info" v-if="message.is_read">READ</span>
                        <span class="badge badge-danger" v-else>NOT READ</span>
                        </td>
                        <td>
                        <span class="badge badge-info" v-if="message.is_replied">REPLIED</span>
                        <span class="badge badge-danger" v-else>NOT REPLIED</span>
                        </td>

                      <!--                <td>   <i-->
                      <!--                        class="fas fa-circle"-->
                      <!--                        :class="message.is_active?'text-success':'text-danger'"-->
                      <!--                ></i></td>-->
                      <td class="text-center">
                        <b-dropdown
                                size="sm"
                                variant="link"
                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                no-caret
                                right
                                no-flip
                        >
                          <template v-slot:button-content>
                            <slot>
                                <span>
                                  <i class="flaticon-more-1"></i>
                                </span></slot>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover ">
                            <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('online-inquiry', 'show')">
                              <a href="#" class="navi-link"  @click="openDialog(message.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-eye"></i>
                                                            </span>
                                <span class="navi-text">View</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('online-inquiry', 'delete')">
                              <a class="navi-link" @click.prevent="deleteOnlineEnquiry(message.id)">
                                <span class="navi-icon">
                                    <v-icon color="red darken-2">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>

<!--                        <span @click="openDialog(message.id)" class="cursor-pointer" title="view">-->
<!--                          <i class="fas fa-eye"></i>-->
<!--                        </span>-->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row" >
              <div class="col-12 text-center">

                <b-pagination
                        :disabled="isLoading"
                        v-if="total>0"
                        @input="getMessages"
                        v-model="page"
                        :total-rows="total"
                        :per-page="perPage"
                        first-number
                        last-number
                ></b-pagination>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Message ref="message" :message_id="message_id" @close_dialog="closeDialog"></Message>
  </div>
  </v-app>
</template>
<script>
import {API_URL} from "@/core/config";
import Message from "./CreateOrUpdate";
import ContactService from "@/services/cms/contact/ContactService";

const contact = new ContactService();

export default {
  name: "Contacts-messages",
  components: {
    Message
  },
  data() {
    return {
      isLoading:false,
      menu2:false,
      menu:false,
      contacts: [],
      message_id: null,
      search: {},
      page: 1,
      total: 0,
      perPage: 25
    };
  },
  mounted() {
    this.getMessages();
  },
  methods: {
    getMessages() {
      this.isLoading = true;
      contact.paginate(this.search, this.page).then(response => {
        this.contacts = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = parseInt(response.data.meta.per_page);
      }).finally(()=>{
        this.isLoading = false;
      });
    },
    openDialog(id = null) {
      this.message_id = id;
      this.$refs["message"].showModal(id);
    },
    closeDialog(id) {
      this.message_id = id;
      this.$refs["message"].hideModal();
      this.getMessages();
    },
    _reset() {
      this.search = {};
      this.getMessages();
    },

    exportData() {
      let url = API_URL + "resources-download-export-csv"
      if (this.search) {
        let query = new URLSearchParams(this.search).toString();
        if (query)
          url = url + '?' + query;
      }
      window.open(url)
    },

    deleteOnlineEnquiry(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            contact
                .delete(id)
                .then((response) => {
                  this.getMessages()
                  this.$snotify.success("Online inquiry deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        }
      });
    },
  }
};
</script>
