var domain = (function(){
    var i=0,domain=document.domain,p=domain.split('.'),s='_gd'+(new Date()).getTime();
    while(i<(p.length-1) && document.cookie.indexOf(s+'='+s)==-1){
        domain = p.slice(-1-(++i)).join('.');
    }
    return domain;
})();
var maindomain = domain.replace(/^[^.]+\./g, "");
maindomain = process.env.VUE_APP_PROTOCOL+'api.'+maindomain+'/';

export const API_URL = maindomain;

export const scoreMenus={
    account_holder:{
        all:true,
        create:true,
        update:true,
        delete:false,
    },
    examiner:{
        all:true,
        create:true,
        update:true,
        delete:false,
    },
    score:{
        all:false,
        create:true,
        update:true,
        delete:false,
    },
    enrolment_year:{
        all:false,
        create:false,
        update:false,
        delete:false,
    },
    support_categories:{
        all:false,
        create:false,
        update:false,
        delete:false,
    },
    scheduling_request:{
        all:true,
        create:true,
        update:true,
        delete:true,
    },
    email_template:{
        all:true,
        create:false,
        update:true,
        delete:false,
    },


}
export default {API_URL,scoreMenus};

